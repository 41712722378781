import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@cherre-frontend/ui';
import { DialogContentText } from '@material-ui/core';
import React from 'react';
import {
  ActivateRule,
  ActivateRuleRule,
  RulesQueryDataRuleLibraryItem,
} from '../../../../../queries/types';
import { ActivateRuleMutation } from '../../../../../queries/activateRuleMutation';
import { useMutation } from 'react-relay';
import { useDataValidationFormContext } from '../dataValidationFormEngine';
import { useCurrentConnector } from '../../../../../hooks/useCurrentConnector';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { connectorDataValidationTabParam } from '../../../../../../../../../recoil';
import {
  getRuleItemStatus,
  RuleStatus,
} from '../../../../../helpers/getRuleItemStatus';

interface DataValidationActivateDialogProps {
  rule?: RulesQueryDataRuleLibraryItem;
}

export const mapValueToString = <T,>(
  value: T,
  externalObjectType: string | undefined,
  parameterName: string
): T | null | string => {
  // TODO: Remove this after demo
  if (parameterName === 'coverage') {
    return '1';
  }

  if (externalObjectType === 'boolean') {
    return String(value) === 'true' ? 'true' : 'false';
  }

  if (!value && typeof value !== 'number') {
    return null;
  }

  return value;
};

export const DataValidationActivateDialog = ({
  rule,
}: DataValidationActivateDialogProps) => {
  const [, setDataValidationV2Param] = useCherreState(
    connectorDataValidationTabParam
  );

  const { connectorComponentName, refetchRuleLibraryItems } =
    useCurrentConnector();
  const { handleSubmit } = useDataValidationFormContext();

  const [commit] = useMutation<ActivateRule>(ActivateRuleMutation);

  const [open, setOpen] = React.useState(false);

  const isDisabled = rule && getRuleItemStatus(rule) === RuleStatus.CREATING;

  const handleClickOpen = handleSubmit((data) => {
    const ruleSpecificProperties: ActivateRuleRule[] =
      data.specificProperties.map((property) => {
        const externalObjectType = property.externalObjectType ?? '';
        const isArray = Array.isArray(property.value);

        const value = mapValueToString(
          property.value,
          externalObjectType,
          property.parameterName
        );

        return {
          externalObjectType,
          propertyName: property.parameterName,
          propertyValue: isArray ? undefined : String(value),
          propertyValues: isArray ? value : undefined,
        };
      });

    const ruleGeneralProperties: ActivateRuleRule[] =
      data.generalProperties.map((property) => {
        const externalObjectType = property.externalObjectType ?? '';
        const isArray = Array.isArray(property.value);

        const value = mapValueToString(
          property.value,
          externalObjectType,
          property.parameterName
        );

        return {
          externalObjectType,
          propertyName: property.parameterName,
          propertyValue: isArray ? undefined : String(value),
          propertyValues: isArray ? value : undefined,
        };
      });

    commit({
      variables: {
        connectorComponentRef: {
          kind: 'Model',
          name: connectorComponentName,
          version: '1',
        },
        ruleLibraryItemRef: {
          kind: 'RuleLibraryItem',
          name: rule?.name || data.name,
          version: rule?.version || data.version,
        },
        ruleGeneralProperties,
        ruleSpecificProperties,
        notificationProperties: [
          {
            propertyName: 'email',
            externalObjectType: 'string',
            propertyValues: data.notificationEmails || [],
          },
        ],
      },
      onCompleted: () => {
        refetchRuleLibraryItems();
      },
    });
    setOpen(true);
  });

  const handleClose = () => {
    setOpen(false);
    setDataValidationV2Param({
      content: 'list',
      detailsTab: null,
      rule: null,
    });
  };

  return (
    <div>
      <Button
        variant='contained'
        sx={{ minWidth: '110px' }}
        onClick={handleClickOpen}
        disabled={isDisabled}
      >
        {rule?.active ? 'Save' : 'Activate'}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='active-rule-dialog-title'
        aria-describedby='active-rule-dialog-description'
        sx={{
          '& .MuiPaper-root': { maxWidth: '640px', padding: '20px' },
          '& .MuiDialogContent-root': { paddingBottom: 0 },
          '& .MuiDialogContentText-root': {
            fontSize: '14px',
            color: 'black',
            marginBottom: 0,
          },
        }}
      >
        <DialogTitle
          id='active-rule-dialog-title'
          sx={{
            fontSize: '20px',
            fontWeight: '700',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <CircularProgress size={24} /> Activating Rule
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText id='active-rule-dialog-description'>
            We’re working on activating your selected rule. You can keep this
            window open to be re-directed to the data validation rules table
            after we’ve set up this rule or you can continue performing other
            actions and view the rule once it’s activated within the data
            validation rules table.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <Button
            onClick={handleClose}
            variant='contained'
            autoFocus
            sx={{ minWidth: '80px' }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
